<template>
  <div class="page">
    <NavBar />

    <div class="container">
      <div class="title">{{ $t('NavBar.aiConfig') }}</div>
      <div class="q-mt-md tabs">
        <van-tabs v-model:active="active" background="transparent" title-inactive-color="#fff"
          title-active-color="#00ecf6" color="#00ecf6">
          <van-tab :title="$t('AiConfig.tab1')"></van-tab>
          <van-tab :title="$t('AiConfig.tab2')"></van-tab>
          <van-tab :title="$t('AiConfig.tab3')"></van-tab>
          <van-tab :title="$t('AiConfig.tab4')"></van-tab>
          <van-tab :title="$t('AiConfig.tab5')"></van-tab>
        </van-tabs>
      </div>
    
      <AiConfigBase v-if="active == 0" />
      <AiConfigData v-if="active == 1" />
      <AiConfigInvest v-if="active == 2" />
      <AiConfigTrade v-if="active == 3" />
      <AiConfigIncome v-if="active == 4" />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { Tab, Tabs } from 'vant';

import NavBar from './components/NavBar'
import AiConfigBase from './components/AiConfigBase'
import AiConfigData from './components/AiConfigData'
import AiConfigInvest from './components/AiConfigInvest'
import AiConfigTrade from './components/AiConfigTrade'
import AiConfigIncome from './components/AiConfigIncome'

export default {
  components: {
    NavBar,
    AiConfigBase,
    AiConfigData,
    AiConfigInvest,
    AiConfigTrade,
    AiConfigIncome,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
  },
  setup() {
    return {
      active: ref(0)
    }
  },
  created() {
  },
  methods: {
  }
}
</script>

<style scoped>
.page {
  min-height: 100vh;
  background-color: #1F2937;
}

.container {
  padding: 15px;
}

.title {
  font-size: 23px;
  font-weight: bold;
  color: #fff;
}
</style>