<template>
  <div>
    <div id="echarts" class="echarts"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import { ref } from 'vue';

export default {
  setup() {
    return {
      myChart: null
    }
  },
  mounted() {
    this.initChart()
    window.addEventListener('resize', this.handleResize);
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
    if(this.myChart) {
      this.myChart.dispose()
    }
  },
  methods: {
    // 屏幕变化触发
    handleResize() {
      this.myChart.resize()
    },
    initChart() {
      let _this = this
      this.myChart = echarts.init(document.getElementById('echarts'))
      let option = {
        grid: {
          top: '6%'
        },
        xAxis: {
          type: 'category',
          data: ['03-15', '03-16', '03-17', '03-18', '03-19', '03-20', '03-21']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: 'line',
            smooth: true,
            lineStyle: {
              color: "#00ecf6"
            },
            itemStyle: {
              normal: {
                color: "#00ecf6"
              }
            }
          }
        ]
      };
      _this.myChart.setOption(option)
    }
  }
}
</script>

<style scoped>
.echarts {
  width: 100%;
  height: 350px;
}
</style>