<template>
  <div>
    <div class="card-title q-mt-lg">{{ $t('NavBar.dingdan') }}</div>
    <div class="tabs q-mt-sm">
      <div class="tabs-name">
        <div>{{ $t('AiConfig.riqi') }}</div>
        <div>{{ $t('AiConfig.leixing') }}</div>
        <div>{{ $t('AiConfig.jine') }}</div>
      </div>
      <div class="tabs-value" v-for="item in 0" :key="item">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  setup() {
    return {
    }
  },
  created() {
  },
  methods: {
  }
}
</script>

<style scoped>
.card-title {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}

.tabs {
  overflow-x: auto;
}

.tabs-name,
.tabs-value {
  display: flex;
  align-items: center;
}

.tabs-name > div,
.tabs-value > div {
  padding: 10px 0;
  flex: 1;
  text-align: center;
  color: #fff;
  background-color: #374151;
}

.tabs-value > div {
  background-color: transparent;
  border-bottom: 1px solid #374151;
}

@media (min-width: 768px) {
  .tabs-name>div,
  .tabs-value>div {
    padding: 10px 0;
    flex: 1;
    text-align: center;
    color: #fff;
    background-color: #374151;
  }
}
</style>