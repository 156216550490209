<template>
  <div class="card-title q-mt-lg">{{$t('AiConfig.biaoxian')}}</div>
  <LineEcharts />
  <div class="card">
    <div class="card-title">{{$t('AiConfig.guanyu')}}</div>
    <div class="card-label">AI Pro 10k</div>
    <div class="row items-center q-mt-md">
      <img src="@/assets/img/base/huibao.png" width="40" />
      <div class="q-ml-sm">
        <div>{{$t('AiConfig.huichou')}}</div>
        <div class="card-value">0.00%</div>
      </div>
    </div>
    <div class="row items-center q-mt-md">
      <img src="@/assets/img/base/aijine.png" width="40" />
      <div class="q-ml-sm">
        <div>{{$t('AiConfig.dingyuejine')}}</div>
        <div class="card-value">10000 USDT</div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import LineEcharts from './LineEcharts'

export default {
  components: {
    LineEcharts,
  },
  setup() {
    return {
    }
  },
  created() {
  },
  methods: {
  }
}
</script>

<style scoped>
.card {
  padding: 15px;
  border-radius: 10px;
  color: #fff;
  border: 1px solid #4B5563;
}

.card-title {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}

.card-label {
  color: grey;
}

.card-value {
  font-size: 16px;
  font-weight: bold;
}
</style>